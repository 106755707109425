import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant'
import { DEBUG_MODE, str_key } from './../utils/index'
import project from './../project/index'
import md5 from './../utils/md5'

var instance = axios.create({
  timeout: 60000 // 1 minute
})

instance.interceptors.request.use(config => {
  const method = config.method.toUpperCase()
  const data = ['GET', 'DELETE'].includes(method) ? config.params : config.data

  const timestamp = parseInt(Date.now() / 1000)
  const encryption = md5(str_key + timestamp)

  // Set token to headers if exist
  const tokenStr = `${project.mid}token`
  const token = localStorage.getItem(tokenStr)

  const newHeaders = { ...config.headers }
  newHeaders['yirun-timestamp'] = timestamp
  newHeaders['yirun-encryption'] = encryption
  newHeaders['yirun-url'] = window.location.host
  token && (newHeaders.Authorization = `Bearer ${token}`)
  config.headers = newHeaders

  // Display request info for debug
  if (DEBUG_MODE) {
    // Filter headers to display
    const headersToDisplay = {}
    for (const k of Object.keys(config.headers)) {
      const value = config.headers[k]
      if (typeof value !== 'object') {
        headersToDisplay[k] = value
      }
    }

    let dataToDisplay = {}
    if (data instanceof FormData) {
      if (data.keys && data.get) {
        const keys = data.keys()
        for (const key of keys) {
          dataToDisplay[key] = data.get(key)
        }
      } else {
        dataToDisplay = data
      }
    } else {
      dataToDisplay = data
    }
    console.log(`-> Request:  ${method} '${config.url}': `, config, dataToDisplay)
  }

  Toast.loading({
    forbidClick: true,
    duration: 0
  })

  return config
}, err => Promise.reject(err))

instance.interceptors.response.use(res => {
  Toast.clear()
  const { headers, data } = res

  if (data.code == '-1') {
    const tokenStr = `${project.mid}token`
    localStorage.removeItem(tokenStr)
    window.location.href = window.location.protocol + '//' + window.location.host + '/' + project.mid + '/login'
    return
  }

  // Display response info for debug
  if (DEBUG_MODE) {
    const config = res.config
    const method = config.method.toUpperCase()
    console.log(`<- Response: ${method} '${config.url}': `, headers, data)
  }

  return res
}, err => Promise.reject(err))

class Requester {
  static get (url = '', data = {}, opts = {}) {
    const config = {
      params: data,
      ...opts
    }
    return instance.get(url, config).then(res => res.data)
  }

  static post (url = '', data = {}, opts = {}) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      transformRequest: [
        data => qs.stringify(data)
      ],
      ...opts
    }
    return instance.post(url, data, config).then(res => res.data)
  }

  // data can be either FormData or plain object
  static formdata (url = '', data = {}, opts = {}) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...opts
    }

    let preparedData = null
    if (data instanceof window.FormData) {
      preparedData = data
    } else {
      preparedData = new window.FormData()
      Object.keys(data).forEach(key => {
        preparedData.append(key, data[key])
      })
    }
    return instance.post(url, preparedData, config).then(res => res.data)
  }
}

export default Requester
