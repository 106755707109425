import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const vuexPersisted = new CreatePersistedState({
  key: 'vuex',
  storage: window.sessionStorage
})

const store = new Vuex.Store({
  state: {
    user: {},
    meeting: null,
    son: null,
    config: {}
  },
  mutations: {
    userEdit(state, data = {}) {
      state.user = data
    },
    seriesEdit(state, data = {}) {
      state.series = data
    },
  },
  plugins: [vuexPersisted]
})

export default store
