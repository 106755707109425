import Vue from 'vue'
import Router from 'vue-router'
import project from './../project/index'

const Home = () => import('./../views/home.vue')
const Index = () => import('./../views/index.vue')
const Login = () => import('./../views/login.vue')
const Exhibition = () => import('../views/exhibition')
const ExhibitionDetail = () => import('../views/exhibition/detail.vue')
const ExhibitionBackups = () => import('../views/exhibition/backups.vue')
const Aboutus = () => import('../views/aboutus.vue')
const Contactus = () => import('../views/contactus.vue')


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: `/${project.mid}/`,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: Exhibition,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_detail',
      name: 'exhibition_detail',
      component: ExhibitionDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_backups',
      name: 'exhibition_backups',
      component: ExhibitionBackups,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: Aboutus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '*',
      redirect: '/home'
    },
  ]
})
